let dataB = {
  name: "流程B",
  nodeList: [
    {
      id: "nodeA",
      name: "流程开始",
      type: "start",
      left: "55px",
      top: "248px",
      ico: "el-icon-time",
    },
    {
      id: "nodeD",
      name: "流程结束",
      type: "end",
      left: "624px",
      top: "248px",
      ico: "el-icon-switch-button",
    },
    {
      id: "nodeB",
      type: "node",
      name: "流程节点1",
      left: "351px",
      top: "96px",
      ico: "el-icon-news",
    },
    {
      id: "nodeC",
      name: "流程节点2",
      type: "node",
      left: "350px",
      top: "378px",
      ico: "el-icon-news",
    }
  ],
  lineList: [
    {
      from: "nodeA",
      to: "nodeB",
    },
    {
      from: "nodeA",
      to: "nodeC",
    },
    {
      from: "nodeB",
      to: "nodeD",
    },
    {
      from: "nodeC",
      to: "nodeD",
    },
  ],
};

export function getDataB() {
  return dataB;
}
