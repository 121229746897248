<template>
  <div v-if="easyFlowVisible" style="min-height: calc(80vh)">
    <div
      style="
        display: flex;
        min-height: calc(100% - 47px);
        border-bottom: 2px solid #e6e6e6;
      "
    >
      <div style="width: 230px; border-right: 1px solid #dce3e8">
        <div class="ef-node-pmenu-item">
          <i class="el-icon-warning-outline"></i>基础信息
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="流程名称" prop="name">
            <el-input v-model="ruleForm.name" placeholder="流程名称"></el-input>
          </el-form-item>
          <el-form-item label="流程实例" prop="example">
            <el-select
              v-model="ruleForm.example"
              placeholder="请选择"
              filterable
              clearable
              @change="changeExampleOptions"
            >
              <el-option
                v-for="item in exampleOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="请假类型"
            prop="example"
            v-if="ruleForm.example === 'EmployeeLeaveApplication'"
          >
            <el-select
              v-model="ruleForm.LeaveTypes"
              placeholder="请选择"
              filterable
              clearable
              multiple
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用单位" prop="example">
            <el-select
              v-model="ruleForm.companyIDs"
              placeholder="请选择"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权重(相同条件权重大优先)" prop="weight">
            <el-input-number
              v-model="ruleForm.weight"
              :min="0"
              :max="1000"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="审核中数据是否可以编辑" prop="editable">
            <el-switch v-model="ruleForm.editable"> </el-switch>
          </el-form-item>
          <el-form-item label="是否启用" prop="enable">
            <el-switch v-model="ruleForm.enable"> </el-switch>
          </el-form-item>
          <el-form-item label="备注" prop="notes">
            <el-input v-model="ruleForm.notes" placeholder="备注"></el-input>
          </el-form-item>
        </el-form>
        <node-menu @addNode="addNode" ref="nodeMenu"></node-menu>
      </div>
      <div id="efContainer" ref="efContainer" class="container" v-flowDrag>
        <template v-for="node in data.nodeList">
          <flow-node
            :id="node.id"
            :key="node.id"
            :node="node"
            :activeElement="activeElement"
            @delNode="deleteNode(node.id)"
            @changeNodeSite="changeNodeSite"
            @nodeRightMenu="nodeRightMenu"
            @clickNode="clickNode"
          >
          </flow-node>
        </template>
        <!-- 给画布一个默认的宽度和高度 -->
        <div style="position: absolute; top: 2000px; left: 2000px">&nbsp;</div>
      </div>
      <!-- 右侧表单 -->
      <div
        style="
          width: 400px;
          border-left: 1px solid #dce3e8;
          background-color: #fbfbfb;
        "
      >
        <flow-node-form
          ref="nodeForm"
          @setLineLabel="setLineLabel"
          @repaintEverything="repaintEverything"
        ></flow-node-form>
      </div>
    </div>
    <div class="buttons">
      <el-button @click="close" class="pop-close">取消</el-button
      ><el-button class="pop-save pop-right" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
// import { jsPlumb } from 'jsplumb'
// 使用修改后的jsplumb
import "./jsplumb";
import { easyFlowMixin } from "@/components/ef/mixins";
import flowNode from "@/components/ef/node";
import nodeMenu from "@/components/ef/node_menu";
import FlowInfo from "@/components/ef/info";
import FlowHelp from "@/components/ef/help";
import FlowNodeForm from "./node_form";
import lodash from "lodash";
import { getDataA } from "./data_A";
import { getDataB } from "./data_B";
import { getDataC } from "./data_C";
import { getDataD } from "./data_D";
import { getDataE } from "./data_E";
import { ForceDirected } from "./force-directed";
import { getTables, getWorkflowFields, getCompanyAll } from "@/api/user";
import { nextTick } from "vue";
export default {
  data() {
    return {
      // jsPlumb 实例
      jsPlumb: null,
      // 控制画布销毁
      easyFlowVisible: true,
      // 控制流程数据显示与隐藏
      flowInfoVisible: false,
      // 是否加载完毕标志位
      loadEasyFlowFinish: false,
      flowHelpVisible: false,
      // 数据
      data: {},
      // 激活的元素、可能是节点、可能是连线
      activeElement: {
        // 可选值 node 、line
        type: undefined,
        // 节点ID
        nodeId: undefined,
        // 连线ID
        sourceId: undefined,
        targetId: undefined,
      },
      zoom: 0.5,

      ruleForm: {
        name: "",
        example: "",
        weight: 1,
        notes: "",
        editable: false,
        enable: true,
        LeaveTypes: [],
        companyIDs: [],
      },
      rules: {
        name: [{ required: true, message: "请输入流程名称", trigger: "blur" }],
      },
      companyList: [],
      exampleOptions: [],
      MainTablSignatureFields: [],
      SubtablSignatureFields: [],
      FilterFileds: [],

      options: [
        {
          name: "病假",
          nameEn: "Sick Leave",
          allName: "病假(Sick Leave)",
          value: 1,
        },
        {
          name: "事假",
          nameEn: "Personal Leave",
          allName: "事假(Personal Leave)",
          value: 2,
        },
        {
          name: "工伤",
          nameEn: "Job Injuries",
          allName: "工伤(Job Injuries)",
          value: 3,
        },
        {
          name: "婚假",
          nameEn: "Marriage Leave",
          allName: "婚假(Marriage Leave)",
          value: 4,
        },
        {
          name: "丧假",
          nameEn: "Bereavement Leave",
          allName: "丧假(Bereavement Leave)",
          value: 5,
        },
        {
          name: "护理假",
          nameEn: "Child Birth Leave",
          allName: "护理假(Child Birth Leave)",
          value: 6,
        },
        {
          name: "朝觐",
          nameEn: "Hajj Leave",
          allName: "朝觐(Hajj Leave)",
          value: 7,
        },
        {
          name: "育儿假",
          nameEn: "Parental Leave",
          allName: "育儿假(Parental Leave)",
          value: 8,
        },
        {
          name: "独生子女护理假",
          nameEn: "Only Child Care Leave",
          allName: "独生子女护理假(Only Child Care Leave)",
          value: 9,
        },
      ],
    };
  },
  // 一些基础配置移动该文件中
  mixins: [easyFlowMixin],
  components: {
    draggable,
    flowNode,
    nodeMenu,
    FlowInfo,
    FlowNodeForm,
    FlowHelp,
  },
  directives: {
    flowDrag: {
      bind(el, binding, vnode, oldNode) {
        if (!binding) {
          return;
        }
        el.onmousedown = (e) => {
          if (e.button == 2) {
            // 右键不管
            return;
          }
          //  鼠标按下，计算当前原始距离可视区的高度
          let disX = e.clientX;
          let disY = e.clientY;
          el.style.cursor = "move";

          document.onmousemove = function (e) {
            // 移动时禁止默认事件
            e.preventDefault();
            const left = e.clientX - disX;
            disX = e.clientX;
            el.scrollLeft += -left;

            const top = e.clientY - disY;
            disY = e.clientY;
            el.scrollTop += -top;
          };

          document.onmouseup = function (e) {
            el.style.cursor = "auto";
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  computed: {
    example() {
      return this.ruleForm.example;
    },
  },
  watch: {
    example(newValue, oldValue) {
      if (newValue) {
        this.getWorkflowFields(newValue);
      }
    },
  },
  created() {
    this.jsPlumb = jsPlumb.getInstance();
    this.data.nodeList = [];
    this.data.lineList = [];
    this.getTables();
    this.getCompanyAll();
  },
  mounted() {
    // this.$nextTick(() => {
    //
    //   this.dataReload(dataA);
    //   // 默认加载流程A的数据、在这里可以根据具体的业务返回符合流程数据格式的数据即可
    //   // this.dataReload(getDataB());
    // });
  },
  methods: {
    // 返回唯一标识
    getUUID() {
      return Math.random().toString(36).substr(3, 10);
    },
    jsPlumbInit() {
      this.jsPlumb.ready(() => {
        // 导入默认配置
        this.jsPlumb.importDefaults(this.jsplumbSetting);
        // 会使整个jsPlumb立即重绘。
        this.jsPlumb.setSuspendDrawing(false, true);
        // 初始化节点
        this.loadEasyFlow();
        // 单点击了连接线, https://www.cnblogs.com/ysx215/p/7615677.html
        this.jsPlumb.bind("click", (conn, originalEvent) => {
          this.activeElement.type = "line";
          this.activeElement.sourceId = conn.sourceId;
          this.activeElement.targetId = conn.targetId;
          this.deleteElement();
        });
        // 连线
        this.jsPlumb.bind("connection", (evt) => {
          let from = evt.source.id;
          let to = evt.target.id;
          if (this.loadEasyFlowFinish) {
            this.data.lineList.push({ from: from, to: to });
          }
        });

        // 删除连线回调
        this.jsPlumb.bind("connectionDetached", (evt) => {
          this.deleteLine(evt.sourceId, evt.targetId);
        });

        // 改变线的连接节点
        this.jsPlumb.bind("connectionMoved", (evt) => {
          this.changeLine(evt.originalSourceId, evt.originalTargetId);
        });

        // 连线右击
        this.jsPlumb.bind("contextmenu", (evt) => {});

        // 连线
        this.jsPlumb.bind("beforeDrop", (evt) => {
          let from = evt.sourceId;
          let to = evt.targetId;

          const nodeB = this.data.nodeList.find((item) => {
            return item.type === "end";
          });
          const nodeA = this.data.nodeList.find((item) => {
            return item.type === "start";
          });

          if (from === nodeB.id) {
            this.$message.error("不支持流程结束节点作为连线起点");
            return false;
          }
          if (to === nodeA.id) {
            this.$message.error("不支持流程开始作为连线终点");
            return false;
          }

          if (from === to) {
            this.$message.error("节点不支持连接自己");
            return false;
          }
          if (this.hasLine(from, to)) {
            this.$message.error("该关系已存在,不允许重复创建");
            return false;
          }
          if (this.hashOppositeLine(from, to)) {
            this.$message.error("不支持两个节点之间连线回环");
            return false;
          }
          this.$message.success("连接成功");
          setTimeout(() => {
            this.setLineLabel(from, to, "x");
          }, 50);
          return true;
        });

        // beforeDetach
        this.jsPlumb.bind("beforeDetach", (evt) => {});
        this.jsPlumb.setContainer(this.$refs.efContainer);
      });
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        // 设置源点，可以拖出线连接其他节点
        this.jsPlumb.makeSource(
          node.id,
          lodash.merge(this.jsplumbSourceOptions, {})
        );
        // // 设置目标点，其他源点拖出的线可以连接该节点
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions);
        if (!node.viewOnly) {
          this.jsPlumb.draggable(node.id, {
            containment: "parent",
            stop: function (el) {
              // 拖拽节点结束后的对调
            },
          });
        }
      }
      // 初始化连线
      for (var i = 0; i < this.data.lineList.length; i++) {
        let line = this.data.lineList[i];
        var connParam = {
          source: line.from,
          target: line.to,
          label: line.label ? line.label : "X",
          connector: line.connector ? line.connector : "",
          anchors: line.anchors ? line.anchors : undefined,
          paintStyle: line.paintStyle ? line.paintStyle : undefined,
        };
        this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
      }
      this.$nextTick(function () {
        this.loadEasyFlowFinish = true;
      });
    },
    // 设置连线条件
    setLineLabel(from, to, label) {
      var conn = this.jsPlumb.getConnections({
        source: from,
        target: to,
      })[0];
      if (!label || label === "") {
        conn.removeClass("flowLabel");
        conn.addClass("emptyFlowLabel");
      } else {
        conn.addClass("flowLabel");
      }
      conn.setLabel({
        label: label,
      });
      this.data.lineList.forEach(function (line) {
        if (line.from == from && line.to == to) {
          line.label = label;
        }
      });
    },
    // 删除激活的元素
    deleteElement() {
      if (this.activeElement.type === "node") {
        this.deleteNode(this.activeElement.nodeId);
      } else if (this.activeElement.type === "line") {
        this.$confirm("确定删除所点击的线吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var conn = this.jsPlumb.getConnections({
              source: this.activeElement.sourceId,
              target: this.activeElement.targetId,
            })[0];
            this.jsPlumb.deleteConnection(conn);
          })
          .catch(() => {});
      }
    },
    // 删除线
    deleteLine(from, to) {
      this.data.lineList = this.data.lineList.filter(function (line) {
        if (line.from == from && line.to == to) {
          return false;
        }
        return true;
      });
    },
    // 改变连线
    changeLine(oldFrom, oldTo) {
      this.deleteLine(oldFrom, oldTo);
    },
    // 改变节点的位置
    changeNodeSite(data) {
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        if (node.id === data.nodeId) {
          node.left = data.left;
          node.top = data.top;
        }
      }
    },
    /**
     * 拖拽结束后添加新的节点
     * @param evt
     * @param nodeMenu 被添加的节点对象
     * @param mousePosition 鼠标拖拽结束的坐标
     */
    addNode(evt, nodeMenu, mousePosition) {
      if (
        nodeMenu.type == "start" &&
        this.data.nodeList.some((x) => {
          return x.type == "start";
        })
      ) {
        this.$message.error("【流程开始】节点已存在,只能选择一个流程开始节点");
        return;
      }
      if (
        nodeMenu.type == "end" &&
        this.data.nodeList.some((x) => {
          return x.type == "end";
        })
      ) {
        this.$message.error("【流程结束】节点已存在,只能选择一个流程结束节点");
        return;
      }
      var screenX = evt.originalEvent.clientX,
        screenY = evt.originalEvent.clientY;
      let efContainer = this.$refs.efContainer;
      var containerRect = efContainer.getBoundingClientRect();
      var left = screenX,
        top = screenY;
      // 计算是否拖入到容器中
      if (
        left < containerRect.x ||
        left > containerRect.width + containerRect.x ||
        top < containerRect.y ||
        containerRect.y > containerRect.y + containerRect.height
      ) {
        this.$message.error("请把节点拖入到画布中");
        return;
      }
      left = left - containerRect.x + efContainer.scrollLeft;
      top = top - containerRect.y + efContainer.scrollTop;
      // 居中
      left -= 85;
      top -= 16;
      var nodeId = this.getUUID();
      // 动态生成名字
      var origName = nodeMenu.name;
      var nodeName = origName;
      var index = 1;
      while (index < 10000) {
        var repeat = false;
        for (var i = 0; i < this.data.nodeList.length; i++) {
          let node = this.data.nodeList[i];
          if (node.name === nodeName) {
            nodeName = origName + index;
            repeat = true;
          }
        }
        if (repeat) {
          index++;
          continue;
        }
        break;
      }
      var node = {
        id: nodeId,
        name: nodeName,
        type: nodeMenu.type,
        left: left + "px",
        top: top + "px",
        ico: nodeMenu.ico,
        state: "success",
      };

      if (node.type === "node") {
        node.auditType = 1; //审核类型
        node.userId = null;
        node.roleId = null;
        node.deptId = null;
        node.auditRefuse = null; //审核未通过
        node.auditBack = null; //驳回
        node.auditMethod = false; //审批方式(会签)
        node.sendMail = false; //邮箱
        node.MainTableSignFields = [];
        node.SubtableSignFields = [];
        node.Filters = [];
        node.FormEditable = false;
      }

      if (node.type === "copy") {
        node.auditType = 1; //审核类型
        node.userId = null;
        node.roleId = null;
        node.deptId = null;
      }
      /**
       * 这里可以进行业务判断、是否能够添加该节点
       */
      this.data.nodeList.push(node);
      this.$nextTick(function () {
        this.jsPlumb.makeSource(nodeId, this.jsplumbSourceOptions);
        this.jsPlumb.makeTarget(nodeId, this.jsplumbTargetOptions);
        this.jsPlumb.draggable(nodeId, {
          containment: "parent",
          stop: function (el) {
            // 拖拽节点结束后的对调
          },
        });
      });
    },
    /**
     * 删除节点
     * @param nodeId 被删除节点的ID
     */
    deleteNode(nodeId) {
      this.$confirm("确定要删除节点" + nodeId + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          /**
           * 这里需要进行业务判断，是否可以删除
           */
          this.data.nodeList = this.data.nodeList.filter(function (node) {
            if (node.id === nodeId) {
              // 伪删除，将节点隐藏，否则会导致位置错位
              // node.show = false
              return false;
            }
            return true;
          });
          this.$nextTick(function () {
            this.jsPlumb.removeAllEndpoints(nodeId);
          });
        })
        .catch(() => {});
      return true;
    },
    clickNode(nodeId) {
      this.activeElement.type = "node";
      this.activeElement.nodeId = nodeId;
      console.log("ruleForm", this.ruleForm);
      let FormEditable = false;
      if (
        this.ruleForm.example == "EmployeeFlightTicketApplication" ||
        this.ruleForm.example == "FlightTicketRescheduleApplication" ||
        this.ruleForm.example == "DemobilizationApplication"
      ) {
        FormEditable = true;
      }
      setTimeout(() => {
        this.$refs.nodeForm.nodeInit(this.data, nodeId, FormEditable);
      }, 1);
    },
    // 是否具有该线
    hasLine(from, to) {
      for (var i = 0; i < this.data.lineList.length; i++) {
        var line = this.data.lineList[i];
        if (line.from === from && line.to === to) {
          return true;
        }
      }
      return false;
    },
    // 是否含有相反的线
    hashOppositeLine(from, to) {
      return this.hasLine(to, from);
    },
    nodeRightMenu(nodeId, evt) {
      this.menu.show = true;
      this.menu.curNodeId = nodeId;
      this.menu.left = evt.x + "px";
      this.menu.top = evt.y + "px";
    },
    repaintEverything() {
      this.jsPlumb.repaint();
    },
    // 流程数据信息
    dataInfo() {
      this.flowInfoVisible = true;
      this.$nextTick(function () {
        this.$refs.flowInfo.init();
      });
    },
    // 加载流程图
    dataReload(data) {
      this.easyFlowVisible = false;
      this.data.nodeList = [];
      this.data.lineList = [];
      this.$nextTick(() => {
        data = lodash.cloneDeep(data);
        this.easyFlowVisible = true;
        this.data = data;
        this.$nextTick(() => {
          this.jsPlumb = jsPlumb.getInstance();
          this.$nextTick(() => {
            this.jsPlumbInit();
            this.clickNode(data.nodeList[0].id);
          });
        });
      });
    },
    // 模拟载入数据dataA
    dataReloadA() {
      this.dataReload(getDataA());
    },
    // 模拟载入数据dataB
    dataReloadB() {
      this.dataReload(getDataB());
    },
    // 模拟载入数据dataC
    dataReloadC() {
      this.dataReload(getDataC());
    },
    // 模拟载入数据dataD
    dataReloadD() {
      this.dataReload(getDataD());
    },
    // 模拟加载数据dataE，自适应创建坐标
    dataReloadE() {
      let dataE = getDataE();
      let tempData = lodash.cloneDeep(dataE);
      let data = ForceDirected(tempData);
      this.dataReload(data);
      this.$message({
        message: "力导图每次产生的布局是不一样的",
        type: "warning",
      });
    },
    //流程实例
    getTables() {
      getTables().then((res) => {
        if (res.status === 200) {
          this.exampleOptions = res.response;
          console.log("11111", this.exampleOptions);
        }
      });
    },
    //适用单位
    getCompanyAll() {
      getCompanyAll().then((res) => {
        if (res.status === 200) {
          this.companyList = res.response;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    getRuleForm(ruleForm) {
      this.ruleForm = {
        name: ruleForm.name,
        example: ruleForm.example,
        weight: ruleForm.weight,
        notes: ruleForm.notes,
        editable: ruleForm.editable,
        enable: ruleForm.enable,
        id: ruleForm.id,
        LeaveTypes: ruleForm.LeaveTypes,
        companyIDs: ruleForm.companyIDs,
      };
      if (ruleForm.example) {
        this.getWorkflowFields(ruleForm.example);
      }
    },
    getWorkflowFields(table) {
      getWorkflowFields({ table }).then((res) => {
        if (res.status === 200) {
          this.MainTablSignatureFields = res.response.MainTablSignatureFields;
          this.SubtablSignatureFields = res.response.SubtablSignatureFields;
          this.FilterFileds = res.response.FilterFileds;
          this.$refs.nodeForm.fieldsInit(
            this.MainTablSignatureFields,
            this.SubtablSignatureFields,
            this.FilterFileds
          );
          this.$refs.nodeForm.clearNodeList();
        }
      });
    },

    //流程实例改变时修改请假类型
    changeExampleOptions() {
      console.log("111", this.ruleForm.example);
      if (this.ruleForm.example !== "EmployeeLeaveApplication") {
        this.ruleForm.LeaveTypes = [];
      }
      this.clickNode(this.activeElement.nodeId);
    },
    save() {
      if (!this.ruleForm.name) {
        this.$message.error("流程名称不能为空");
        return;
      }
      if (!this.ruleForm.example) {
        this.$message.error("请选择左侧流程实例");
        return;
      }
      if (this.data.nodeList.length < 3) {
        this.$message.error("请至少选择一个流程节点");
        return;
      }
      this.$emit("save", { data: this.data, ruleForm: this.ruleForm });
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
  padding: 0 10px;
}
</style>
<style scoped>
.demo-ruleForm >>> .el-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.demo-ruleForm >>> .el-form-item__label {
  width: 100% !important;
  text-align: left;
}
.demo-ruleForm >>> .el-form-item__content {
  display: block;
  margin-left: 0 !important;
}
.demo-ruleForm >>> .el-input__inner {
  width: 100%;
}
.demo-ruleForm >>> .el-input-number {
  width: 100%;
}
.buttons {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
</style>
