<template>
  <div>
    <div class="ef-node-form">
      <div class="ef-node-pmenu-item"><i class="el-icon-news"></i>节点属性</div>
      <div class="ef-node-form-body">
        <el-form
          :model="node"
          :rules="rules"
          ref="dataForm"
          v-show="type === 'node'"
          class="demo-ruleForm"
        >
          <el-form-item label="节点名称" prop="name">
            <el-input
              v-model="node.name"
              placeholder="请输入节点名称"
            ></el-input>
          </el-form-item>
          <template v-if="node.type === 'node'">
            <el-form-item label="审批类型" prop="auditType">
              <el-select
                v-model="node.auditType"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in auditTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="用户信息"
              prop="userId"
              v-if="node.auditType === 1"
            >
              <el-select
                v-model="node.userId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="角色信息"
              prop="roleId"
              v-if="node.auditType === 2"
            >
              <el-select
                v-model="node.roleId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in roleOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="部门信息"
              prop="deptId"
              v-if="node.auditType === 3"
            >
              <el-select
                v-model="node.deptId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in deptOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="审批未通过" prop="auditRefuse">
              <el-select
                v-model="node.auditRefuse"
                placeholder="审批未通过"
                filterable
                clearable
              >
                <el-option
                  v-for="item in refuseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审批驳回" prop="auditBack">
              <el-select
                v-model="node.auditBack"
                placeholder="审批驳回"
                filterable
                clearable
              >
                <el-option
                  v-for="item in refuseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核后发送邮件通知" prop="sendMail">
              <el-switch v-model="node.sendMail"> </el-switch>
            </el-form-item>
            <el-form-item prop="auditMethod">
              <div>
                启用会签
                <el-popover
                  placement="top-start"
                  title="提示"
                  width="200"
                  trigger="hover"
                  content="开启时须所有审批人同意否则一名审批人处理即可!"
                >
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
              </div>
              <el-switch v-model="node.auditMethod"> </el-switch>
            </el-form-item>
            <el-form-item label="允许修改表单" prop="FormEditable" v-if="isFormEditable">
              <el-switch v-model="node.FormEditable"> </el-switch>
            </el-form-item>
          </template>
          <template v-if="node.type === 'copy'">
            <el-form-item label="审批类型" prop="auditType">
              <el-select
                v-model="node.auditType"
                placeholder="请选择"
                filterable
                clearable
              >
                <el-option
                  v-for="item in auditTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="用户信息"
              prop="userId"
              v-if="node.auditType === 1"
            >
              <el-select
                v-model="node.userId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="角色信息"
              prop="roleId"
              v-if="node.auditType === 2"
            >
              <el-select
                v-model="node.roleId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in roleOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="部门信息"
              prop="deptId"
              v-if="node.auditType === 3"
            >
              <el-select
                v-model="node.deptId"
                multiple
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in deptOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="ef-node-pmenu-item">
        <i class="el-icon-tickets"></i>电子签名回填
      </div>
      <div class="ef-node-form-body" v-if="node.type === 'node'">
        <el-form
          :model="node"
          :rules="rules"
          ref="dataForm"
          class="demo-ruleForm"
        >
          <el-form-item
            label="主表"
            prop="MainTablSignatureFields"
            v-if="
              MainTablSignatureFields && MainTablSignatureFields.length !== 0
            "
          >
            <el-select
              v-model="node.MainTableSignFields"
              placeholder="请选择"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in MainTablSignatureFields"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="子表"
            prop="SubtableSignFields"
            v-if="SubtablSignatureFields && SubtablSignatureFields.length !== 0"
          >
            <el-select
              v-model="node.SubtableSignFields"
              placeholder="请选择"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in SubtablSignatureFields"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-form>
      </div>

      <div class="ef-node-pmenu-item flex-box">
        <div><i class="el-icon-news"></i>条件设置</div>
        <div
          class="add"
          v-if="node.type === 'node' && FilterFileds.length !== 0"
          @click="addConditionsItem"
        >
          + 添加字段
        </div>
      </div>
      <div class="ef-node-form-body">
        <div class="herder-box">
          <div class="herder-1">字段</div>
          <div class="herder-2">条件</div>
          <div class="herder-3">值</div>
          <div class="herder-4">操作</div>
        </div>
        <div
          class="conditions"
          v-for="(item, index) in node.Filters"
          :key="index"
          v-if="node.type === 'node'"
        >
          <div class="conditions-1">
            <el-select
              v-model="item.field"
              placeholder="请选择"
              @change="changeField(index)"
              size="mini"
              filterable
            >
              <el-option
                v-for="item in FilterFileds"
                :key="item.Field"
                :label="item.Name"
                :value="item.Field"
              >
              </el-option>
            </el-select>
          </div>
          <div class="conditions-2">
            <el-select
              v-model="item.filterType"
              placeholder="请选择"
              filterable
              clearable
              size="mini"
            >
              <el-option
                v-for="item in filterTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="conditions-3">
            <el-select
              size="mini"
              v-if="item.filtersData"
              multiple
              v-model="item.value"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="cItem in item.filtersData"
                :key="cItem.Key"
                :label="cItem.Text"
                :value="cItem.Key"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.value"
              size="mini"
              placeholder="请输入"
              v-else
            />
          </div>
          <div class="conditions-4">
            <i class="el-icon-delete" @click="deleteItem(index)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getNodeDic, getWorkflowFields } from "@/api/user";
export default {
  watch: {
    node: {
      //监听的对象
      deep: true, //深度监听设置为 true
      handler: function (newV, oldV) {
        this.save();
      },
    },
  },
  data() {
    return {
      visible: true,
      // node 或 line
      type: "node",
      node: {},
      line: {},
      data: {},
      rules: {
        name: [{ required: true, message: "请输入节点名称", trigger: "blur" }],
        auditType: [
          { required: true, message: "请选择审批类型", trigger: "blur" },
        ],
        userId: [
          { required: true, message: "请选择用户信息", trigger: "blur" },
        ],
        roleId: [
          { required: true, message: "请选择角色信息", trigger: "blur" },
        ],
        deptId: [
          { required: true, message: "请选择部门信息", trigger: "blur" },
        ],
      },
      auditTypeOptions: [
        {
          value: 1,
          label: "按用户审批",
        },
        {
          value: 2,
          label: "按角色审批",
        },
        {
          value: 3,
          label: "按部门审批",
        },
      ],
      userOptions: [
        {
          value: 1,
          label: "一般用户",
        },
        {
          value: 2,
          label: "测试用户",
        },
      ],
      roleOptions: [
        {
          value: 1,
          label: "超级管理员",
        },
        {
          value: 2,
          label: "管理员",
        },
        {
          value: 3,
          label: "信息员",
        },
      ],
      deptOptions: [
        {
          value: 1,
          label: "信息技术中心",
        },
        {
          value: 2,
          label: "客服一区",
        },
        {
          value: 3,
          label: "客服二区",
        },
      ],
      refuseOptions: [
        {
          value: 1,
          label: "返回上一节点",
        },
        {
          value: 2,
          label: "流程重新开始",
        },
        {
          value: 0,
          label: "流程结束",
        },
      ],
      stateList: [
        {
          state: "success",
          label: "成功",
        },
        {
          state: "warning",
          label: "警告",
        },
        {
          state: "error",
          label: "错误",
        },
        {
          state: "running",
          label: "运行中",
        },
      ],
      filterTypeOptions: [
        {
          value: "=",
          label: "等于(=)",
        },
        {
          value: "!=",
          label: "不等于(!=)",
        },
        {
          value: ">",
          label: "大于(>)",
        },
        {
          value: ">=",
          label: "大于等于(>=)",
        },
        {
          value: "<",
          label: "小于(<)",
        },
        {
          value: "<=",
          label: "小于等于(<=)",
        },
        {
          value: "in",
          label: "包括(in)",
        },
        {
          value: "like",
          label: "模糊匹配(like)",
        },
        {
          value: "or",
          label: "或者(or)",
        },
      ],
      MainTablSignatureFields: [],
      SubtablSignatureFields: [],
      Conditions: [],
      FilterFileds: [],
      filtersData: null,
      isFormEditable: false,
    };
  },
  methods: {
    /**
     * 表单修改，这里可以根据传入的ID进行业务信息获取
     * @param data
     * @param id
     */
    nodeInit(data, id, isBool) {
      this.type = "node";
      this.data = data;
      this.isFormEditable = isBool;
      data.nodeList.filter((node) => {
        if (node.id === id) {
          this.node = cloneDeep(node);
          console.log("this.node", this.node, this.FilterFileds);
        }
        if (this.FilterFileds && this.FilterFileds.length !== 0) {
          if (!this.node.Filters) {
            this.node.Filters = [];
          }
        }
      });
    },
    fieldsInit(data1, data2, data3) {
      this.MainTablSignatureFields = data1;
      this.SubtablSignatureFields = data2;
      this.FilterFileds = data3;
    },
    lineInit(line) {
      this.type = "line";
      this.line = line;
    },
    // 修改连线
    saveLine() {
      this.$emit("setLineLabel", this.line.from, this.line.to, this.line.label);
    },
    clearNodeList() {
      this.node.MainTableSignFields = [];
      this.node.SubtableSignFields = [];
      this.node.Filters = [];
    },
    save() {
      this.data.nodeList.filter((node) => {
        if (node.id === this.node.id) {
          node.name = this.node.name;
          node.left = this.node.left;
          node.top = this.node.top;
          node.ico = this.node.ico;
          if (node.type === "node") {
            node.auditType = this.node.auditType; //审核类型
            node.userId = this.node.userId;
            node.roleId = this.node.roleId;
            node.deptId = this.node.deptId;
            node.auditRefuse = this.node.auditRefuse; //审核未通过
            node.auditBack = this.node.auditBack; //驳回
            node.auditMethod = this.node.auditMethod; //审批方式(会签)
            node.sendMail = this.node.sendMail; //邮箱
            node.MainTableSignFields = this.node.MainTableSignFields;
            node.SubtableSignFields = this.node.SubtableSignFields;
            node.Filters = this.node.Filters;
            node.FormEditable = this.node.FormEditable;
          }
          if (node.type === "copy") {
            node.auditType = this.node.auditType; //审核类型
            node.userId = this.node.userId;
            node.roleId = this.node.roleId;
            node.deptId = this.node.deptId;
          }
          this.$emit("repaintEverything");
        }
      });
    },
    getNodeDic() {
      getNodeDic().then((res) => {
        if (res.status === 200) {
          this.deptOptions = res.response.depts;
          this.userOptions = res.response.users;
          this.roleOptions = res.response.roles;
        }
      });
    },
    addConditionsItem() {
      const item = {
        field: null,
        filterType: null,
        value: null,
        filtersData: null,
      };
      this.node.Filters.push(JSON.parse(JSON.stringify(item)));
      this.$forceUpdate();
      console.log("this.node.Filters", this.node.Filters);
    },
    changeField(index) {
      this.node.Filters[index].value = null;
      this.FilterFileds.forEach((item) => {
        if (item.Field === this.node.Filters[index].field) {
          this.node.Filters[index].filtersData = item.Data;
        }
      });
      this.$forceUpdate();
    },
    deleteItem(index) {
      this.node.Filters.splice(index, 1);
    },
  },
  created() {
    this.getNodeDic();
  },
};
</script>

<style>
.ef-node-form-body {
  padding: 0;
}
.el-node-form-tag {
  position: absolute;
  top: 50%;
  margin-left: -15px;
  height: 40px;
  width: 15px;
  background-color: #fbfbfb;
  border: 1px solid rgb(220, 227, 232);
  border-right: none;
  z-index: 0;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}
.flex-box > .add {
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
  margin-right: 20px;
}
.herder-box {
  display: flex;
  font-weight: bold;
  padding-bottom: 12px;
  /* height: 30px;
  line-height: 30px; */
}
.conditions {
  display: flex;
  margin-bottom: 12px;
}

.conditions-1 {
  width: 90px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
  margin-left: 10px;
}
.conditions-2 {
  width: 90px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
}
.conditions-3 {
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.conditions-4 {
  width: 40px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e20404;
}
.el-icon-delete {
  cursor: pointer;
}
.herder-1 {
  width: 90px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
  margin-left: 10px;
}
.herder-2 {
  width: 90px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
}
.herder-3 {
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.herder-4 {
  width: 40px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
  margin-right: 10px;
}
</style>
<style scoped>
.el-select {
  width: 100%;
}
.demo-ruleForm {
  padding: 0 10px;
}
.demo-ruleForm >>> .el-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.demo-ruleForm >>> .el-form-item__label {
  width: 100% !important;
  text-align: left;
}
.demo-ruleForm >>> .el-form-item__content {
  display: block;
  margin-left: 0 !important;
}
.demo-ruleForm >>> .el-input__inner {
  width: 100%;
}
.demo-ruleForm >>> .el-input-number {
  width: 100%;
}
.el-icon-question {
  margin-left: 10px;
  color: #84878d;
  cursor: pointer;
}
</style>
